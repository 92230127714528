.App {
  font-family: "roboto", sans-serif;
  user-select: none;
}
html {
  background-color: #d7d7d7;
}
.disclaimer {
  font-size: 8pt;
  opacity: 0.5;
  position: fixed;
  bottom: 0;
  right: 0;
  text-align: right;
  max-width: 75%;
  margin-right: 0.125rem;
}
.copyright {
  font-size: 8pt;
  opacity: 0.5;
  position: fixed;
  bottom: 0;
  left: 0;
  text-align: left;
  max-width: 25%;
  margin-left: 0.125rem;
}

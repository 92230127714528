div .react-transform-component {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  user-select: none;
  z-index: -10;
}
.mapContainer {
  cursor: grab;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.mapContainer.panning {
  cursor: grabbing;
}

@media only screen and (max-width: 400px) {
  .menuBox {
    width: 90vw !important;
  }
}
@media only screen and (max-width: 600px) and (min-width: 400px) {
  .menuBox {
    width: 70vw !important;
  }
}
@media only screen and (max-width: 800px) and (min-width: 600px) {
  .menuBox {
    width: 55vw !important;
  }
}
@media only screen and (max-width: 1000px) and (min-width: 800px) {
  .menuBox {
    width: 40vw !important;
  }
}

.menuBox {
  position: absolute;
  top: 0vh;
  left: 0px;
  background: #f0f0f0;
  width: min(25vw, 450px);
  height: 100%;
  border: 5px solid #c5b358;
  box-sizing: border-box;
  border-radius: 0px 32px 32px 0px;
  border-left: 0;
  display: flex;
  align-items: center;
  z-index: 99999;
  transform: translateX(0);
  transition: transform 0.5s ease-out;
}
.menuBox.closed {
  transform: translateX(-450px);
}
.menuBoxInner {
  display: flex;
  width: calc(100% - 16px);
  height: calc(100% - 32px);
  flex-direction: column;
  overflow: auto;
}
::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  background: #888;
}
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.menuHeader {
  display: flex;
  margin-left: 1rem;
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0.5rem;
  justify-content: space-between;
  position: sticky;
  top: 0px;
  background: #f0f0f0;
  z-index: 100000000;
  padding-bottom: 0.75rem;
  border-bottom: 1px solid #002049;
}
.headerTitle {
  margin: 0px;
  color: #002049;
  user-select: none;
}
.headerCloseBtn {
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
}
.headerCloseBtn > i {
  font-size: 2rem;
  color: #002049;
}
.toggleOptions {
  display: flex;
  width: 95%;
  justify-content: space-between;
  margin-bottom: 1rem;
}
.options {
  display: flex;
  flex-direction: column;
  width: calc(100% - 16px);
  flex: 1 0 auto;
  margin-left: 1rem;
  margin-top: 1rem;
}
.openMenuBtn {
  position: absolute;
  z-index: 500;
  top: 16px;
  left: 16px;
  user-select: none;
  cursor: pointer;
}
.openMenuBtn > i {
  font-size: 2rem;
  color: #002049;
}
.clickCapture {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 750;
  display: none;
}
.clickCapture.enabled {
  display: initial;
}
.hiddenItem {
  max-height: 0px !important;
}

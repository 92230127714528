.treeItem {
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-bottom: 0.5rem;
  overflow: hidden;
  max-height: 5rem;
  transition: max-height 0.25s ease-out;
  user-select: none;
  color: #002049;
}
.itemTitle {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.itemContents {
  margin-top: 0.25rem;
  margin-left: 2rem;
}
.treeItem.closed {
  max-height: 1.5rem;
}
.dropdown {
  border: none;
  border-bottom: 1px solid #002049;
  color: #002049;
  background-color: transparent;
  outline: none !important;
  margin-right: 0.25rem;
}
.dropdown.long {
  width: 50%;
}
